@layer components {
  .display {
    @apply text-[4rem] leading-[4.25rem] -tracking-[0.1875rem];
  }

  .heading-xl {
    @apply text-[3rem] leading-[3.375rem] -tracking-[0.125rem];
  }

  .heading-lg {
    @apply text-[2.5rem] leading-[2.875rem] -tracking-[0.125rem];
  }

  .heading-md {
    @apply text-[2.25rem] leading-[2.5rem] -tracking-[0.0625rem];
  }

  .heading-sm {
    @apply text-[2rem] leading-[2.25rem] -tracking-[0.0625rem];
  }

  .heading-xs {
    @apply text-[1.75rem] leading-[2rem] -tracking-[0.03125rem];
  }

  .title-lg {
    @apply text-[1.5rem] leading-[1.625rem] -tracking-[0.03125rem];
  }

  .title-md {
    @apply text-[1.25rem] leading-[1.5rem] tracking-none;
  }

  .title-sm {
    @apply text-[1.125rem] leading-[1.375rem] tracking-none;
  }

  .title-xs {
    @apply text-[1rem] leading-[1.25rem] tracking-none;
  }

  .title-xxs {
    @apply text-[0.875rem] leading-[1.125rem] tracking-none;
  }

  .eyebrow-lg {
    @apply text-[0.9375rem] uppercase leading-tight tracking-[0.0625rem];
  }

  .eyebrow-md {
    @apply text-[0.8125rem] uppercase leading-[1rem] tracking-[0.0625rem];
  }

  .eyebrow-sm {
    @apply text-[0.6875rem] uppercase leading-[0.875rem] tracking-[0.0625rem];
  }

  .paragraph-xl {
    @apply text-[1.25rem] leading-[2rem] tracking-none;
  }

  .paragraph-lg {
    @apply text-[1.125rem] leading-[1.875rem] tracking-none;
  }

  .paragraph-md {
    @apply text-[1rem] leading-[1.75rem] tracking-none;
  }

  .paragraph-sm {
    @apply text-[0.875rem] leading-[1.625rem] tracking-none;
  }

  .caption-lg {
    @apply text-[1rem] leading-[1.25rem] tracking-none;
  }

  .caption-md {
    @apply text-[0.875rem] leading-[1.125rem] tracking-none;
  }

  .caption-sm {
    @apply text-[0.75rem] leading-[1rem] tracking-none;
  }

  .footer {
    @apply text-[0.625rem] leading-[0.875rem] tracking-none;
  }
}
